<template>
  <div
    class="widgetContainer widgetContainer--scrollable widgetContainer--full-screen widgetContainer--center
      appStatus--approved
      accountCreated
    " v-if="getSelectedAccount">
    <div class="widgetContainer__body">
      <LottieAnimation animation-name="success" reference="account-created" :loop="true" />
      <p class="title">
        {{ $t('acc_detail_title') }}
      </p>
      <p class="description">
        {{ $t('acc_detail_description', { value1: getSelectedAccount.label }) }}
      </p>

      <div class="listWithAccountNumbers">
        <ul>
          <li>
            <p class="name">
              {{ $t('acc_detail_num_title') }}
            </p>
            <p class="value">
              {{ getSelectedAccount.accountNumber }}
            </p>
          </li>
          <li>
            <p class="name">
              {{ $t('acc_detail_rout_title') }}
            </p>
            <p class="value">
              {{ getSelectedAccount.routingNumber }}
            </p>
          </li>
        </ul>
      </div>

      <div class="account__disclaimer">
        <p>{{ accountDisclaimerText }}</p>
      </div>

      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" @click="next" class="el-button__brand brand">
          {{ $t('acc_detail_start_button') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getBrandKey } from '@/utils/env';
import { mapGetters, mapMutations } from 'vuex';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  name: 'AccountCreated',
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),

    accountDisclaimerText(){
      const key = getBrandKey();
      return this.$t(key !== 'solid' ? (key+'_acc_setup_disclaimer') : 'acc_setup_disclaimer')
    }
  },
  created() {
    window.analytics && window.analytics.track('createAccount');
  },
  methods: {
    ...mapMutations('transaction', ['setRecentTransactions']),
    ...mapMutations('card', ['updateCards']),
    next() {
      this.setRecentTransactions([]);
      this.updateCards({
        data: [],
        total: 0
      });
      this.$emit('next');
    }
  }
};
</script>

<style lang="scss" scoped>
  .accountCreated {
    .widgetContainer__body {
      justify-content: flex-start;
    }
  }
</style>
